import getConfig from 'next/config'
import {
  impressionTypes,
  getImpressionData,
  clearTrackedData,
} from './impressionsTracker'
import { post, headers } from './fetch'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import SessionStorageService from '../utils/SessionStorageService'
import { SPLIT_FEATURES } from '../utils/constants'
import { getConfigFor } from '../utils/configService'
const {
  publicRuntimeConfig: { API_URL, MARKETING_API_URL },
} = getConfig()

const getXSessionId = () => {
  const sessionId = SessionStorageService.getItem('tt_sessionId')
  if (sessionId) {
    return sessionId.replace(/"/g, '')
  } else {
    return null
  }
}

const headersForCitrus = () => {
  const sessionId = getXSessionId()
  return {
    ...headers(),
    ...(sessionId ? { 'x-session-id': sessionId } : {}),
  }
}
const isCitrusProductTrackingEnabled = (remoteConfig = {}) => {
  let citrusConfig = remoteConfig?.citrusAdConfig?.defaultValue?.value || {}
  citrusConfig =
    typeof citrusConfig === 'string' ? JSON.parse(citrusConfig) : citrusConfig

  return !!citrusConfig?.enableProductTracking || false
}

const trackCitrusAdProductClick = (adIds = [], remoteConfig = {}) => {
  const validAdIds = compact(adIds).map(String)
  if (!isEmpty(validAdIds) && isCitrusProductTrackingEnabled(remoteConfig)) {
    const requestUrl = `${API_URL}/marketing/tracking`
    post(requestUrl, {
      headers: headersForCitrus(),
      body: JSON.stringify({
        type: 'Clicks',
        adIds: validAdIds,
        platform: 'web',
      }),
    })
  }
}

const trackCitrusAdProduct = (remoteConfig = {}) => {
  if (isCitrusProductTrackingEnabled(remoteConfig)) {
    const citrusProductImpressionData = getImpressionData(
      impressionTypes.CITRUS_PRODUCT_IMPRESSION
    )

    if (citrusProductImpressionData.length) {
      const requestUrl = `${API_URL}/marketing/tracking`
      post(requestUrl, {
        headers: headersForCitrus(),
        body: JSON.stringify({
          type: 'Impressions',
          adIds: citrusProductImpressionData,
          platform: 'web',
        }),
      })
      clearTrackedData(impressionTypes.CITRUS_PRODUCT_IMPRESSION)
    }
  }
}

const trackCitrusBannerClick = ({ banner }, isHomepageBanner) => {
  const isCitrusBanner = banner.source === 'citrus'
  let requestUrl = `${API_URL}/marketing/tracking`
  let payload = {
    type: 'Clicks',
    adIds: [banner.adId.toString()],
    platform: 'web',
  }

  const { isEnabled: isCitrusTrackingV2Enabled } = getConfigFor(
    SPLIT_FEATURES.FE_CITRUS_TRACKING_V2
  )
  if (isCitrusTrackingV2Enabled && isCitrusBanner && isHomepageBanner) {
    requestUrl = `${MARKETING_API_URL}/tracking`
    payload = [
      {
        adId: banner.adId.toString(),
        type: 'clicks',
        source: banner.source.toString(),
      },
    ]
  }

  post(requestUrl, {
    headers: headersForCitrus(),
    body: JSON.stringify(payload),
  })
}

const trackCitrusBannerImpressions = () => {
  const bannerImpressionData = getImpressionData(
    impressionTypes.CITRUS_BANNER_IMPRESSION
  )
  if (bannerImpressionData.length > 0) {
    let v1ImpressionData = bannerImpressionData

    // make another call only for citrus banner
    const { isEnabled: isCitrusTrackingV2Enabled } = getConfigFor(
      SPLIT_FEATURES.FE_CITRUS_TRACKING_V2
    )
    if (isCitrusTrackingV2Enabled) {
      v1ImpressionData = []
      const citrusImpressionData = []
      for (const data of bannerImpressionData) {
        const { source, isHomepageBanner } = data
        if (source === 'citrus' && isHomepageBanner) {
          citrusImpressionData.push(data)
        } else {
          v1ImpressionData.push(data)
        }
      }

      if (citrusImpressionData.length > 0) {
        const payload = citrusImpressionData.map(({ adId, source }) => ({
          adId,
          source,
          type: 'impressions',
        }))
        post(`${MARKETING_API_URL}/tracking`, {
          headers: headersForCitrus(),
          body: JSON.stringify(payload),
        })
      }
    }

    if (v1ImpressionData.length > 0) {
      const payload = {
        type: 'Impressions',
        adIds: v1ImpressionData.map(({ adId }) => adId),
        platform: 'web',
      }

      post(`${API_URL}/marketing/tracking`, {
        headers: headersForCitrus(),
        body: JSON.stringify(payload),
      })
    }
    clearTrackedData(impressionTypes.CITRUS_BANNER_IMPRESSION)
  }
}

export {
  getXSessionId,
  headersForCitrus,
  isCitrusProductTrackingEnabled,
  trackCitrusAdProductClick,
  trackCitrusAdProduct,
  trackCitrusBannerClick,
  trackCitrusBannerImpressions,
}
